import { ContactForm } from '@/models/interfaces/ContactForm'

export default class ContactFormDto {
  public readonly fullName: string
  public readonly email: string
  public readonly phone: string
  public readonly subject: string
  public readonly message: string
  public readonly clientId: string
  public readonly campaignRequestId: string

  constructor (data: ContactForm) {
    this.fullName = data.fullName ? String(data.fullName) : ''
    this.email = data.email ? String(data.email) : ''
    this.phone = data.phone ? String(data.phone) : '0'
    this.subject = data.subject ? String(data.subject) : ''
    this.message = data.message ? String(data.message) : ''
    this.clientId = data.clientId ? String(data.clientId) : ''
    this.campaignRequestId = data.campaignRequestId ? String(data.campaignRequestId) : ''
  }
}
