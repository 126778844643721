
import { defineComponent, reactive, ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { axiosApi } from '@/models/http/axiosApi'
import { useField, useForm, useResetForm } from 'vee-validate'
import * as yup from 'yup'
import _ from 'lodash'
import { useCookie } from 'vue-cookie-next'
import CCPAFormDto from '@/models/dto/CCPAFormDto'
import { CCPAForm, Validation } from '@/models/interfaces/CCPAForm'
import { segmentTracking } from '@/helpers/clickEventTracking'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'CCPA',
  setup () {
    useMeta({
      title: 'CCPA'
    })
    const schema = yup.object({
      firstName: yup.string()
        .label('First Name')
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .min(2),
      lastName: yup.string()
        .label('Last Name')
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .min(2),
      zipcode: yup.string()
        .label('Zipcode')
        .required('Zipcode is a required field')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits'),
      email: yup.string()
        .required()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      phone: yup.number()
        .required()
        .typeError('Must be only digits')
        .test('len', 'Must be a valid phone number', val => val?.toString().length === 10),
      reason: yup.number()
        .typeError('You must choose an option')
        .required()
    })
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: firstName } = useField('firstName')
    const { value: lastName } = useField('lastName')
    const { value: zipcode } = useField('zipcode')
    const { value: email } = useField('email')
    const { value: phone } = useField('phone')
    const { value: reason } = useField('reason')
    const consumer = ref(true as boolean)
    const { getCookie } = useCookie()
    const clientCookie = getCookie('client_id') ?? ''
    const { value: clientId } = ref(clientCookie)
    const campaignRequestId = getCookie('campaign_request_id') ?? ''
    const title = useStore().state.siteSettings.title ? useStore().state.siteSettings.title : ''
    const submitted = ref(false as boolean)
    const data = reactive({
      firstName,
      lastName,
      zipcode,
      email,
      phone,
      reason,
      consumer,
      clientId,
      campaignRequestId
    })

    const checkFields = computed<string>(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && firstName.value && lastName.value && zipcode.value && email.value && phone.value && reason.value
          ? 'cursor-pointer'
          : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )
    const resetForm = useResetForm()
    const sendForm = () => {
      if (_.size(errors.value) === 0 && firstName.value && lastName.value && zipcode.value && email.value && phone.value && reason.value) {
        segmentTracking('Clicked On Submit On Page')
        axiosApi.post('/ccpa-form', new CCPAFormDto(data as CCPAForm))
          .then(() => {
            submitted.value = true
          })
        consumer.value = true
        resetForm()
      }
    }

    return {
      errors,
      firstName,
      lastName,
      zipcode,
      email,
      phone,
      reason,
      consumer,
      clientId,
      submitted,
      data,
      checkFields,
      sendForm,
      title
    }
  }
})
