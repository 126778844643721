import { UnsubscribeForm } from '@/models/interfaces/UnsubscribeForm'

export default class ContactFormDto {
  public readonly phone: string
  public readonly reason: number
  public readonly email: string
  public readonly otherReason: string
  public readonly clientId: string
  public readonly campaignRequestId: string

  constructor (data: UnsubscribeForm) {
    this.phone = data.phone ? String(data.phone) : '0'
    this.reason = data.reason ? Number(data.reason) : 0
    this.email = data.email ? String(data.email) : ''
    this.otherReason = data.otherReason ? String(data.otherReason) : ''
    this.clientId = data.clientId ? String(data.clientId) : ''
    this.campaignRequestId = data.campaignRequestId ? String(data.campaignRequestId) : ''
  }
}
