
import { defineComponent, ref, reactive, computed } from 'vue'
import { useMeta } from 'vue-meta'
import { axiosApi } from '@/models/http/axiosApi'
import { useField, useForm, useResetForm } from 'vee-validate'
import * as yup from 'yup'
import _ from 'lodash'
import { useCookie } from 'vue-cookie-next'
import { UnsubscribeForm, Validation } from '@/models/interfaces/UnsubscribeForm'
import UnsubscribeFormDto from '@/models/dto/UnsubscribeFormDto'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Unsubscribe',
  setup () {
    useMeta({
      title: 'Unsubscribe'
    })
    const schema = yup.object().shape({
      email: yup.string().email()
        .when('email', {
          is: (value: string) => value?.length,
          then: yup.string().required()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
        }),
      phone: yup.string()
        .when('phone', {
          is: (value: string) => value?.length,
          then: yup.string()
            .notRequired()
            .typeError('Must be only digits')
            .matches(/^[0-9]{10}$/, 'Must be a valid phone number')
        }),
      reason: yup.number()
        .typeError('You must choose an option')
        .required(),
      otherReason: yup.string()
        .required()
        .max(255)
        .when('reason', {
          is: 4,
          then: yup.string().required('You must enter a reason')
        })
    }, [['email', 'email'], ['phone', 'phone']])
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })

    const { value: email } = useField('email')
    const { value: phone } = useField('phone')
    const { value: reason } = useField('reason')
    const { value: otherReason } = useField('otherReason')
    const { getCookie } = useCookie()
    const clientCookie = getCookie('client_id') ?? ''
    const { value: clientId } = ref(clientCookie)
    const campaignRequestId = getCookie('campaign_request_id') ?? ''
    const submitted = ref(false as boolean)
    const data = reactive({
      email,
      phone,
      reason,
      otherReason,
      clientId,
      campaignRequestId
    })

    const checkFields = computed<string>(
      () => {
        const classes = ref('')
        if (_.size(errors.value) === 0 && (email.value || phone.value) && reason.value) {
          if (reason.value === 4 && otherReason.value) {
            classes.value = 'cursor-pointer'
          } else if (reason.value !== 4) {
            classes.value = 'cursor-pointer'
          } else {
            classes.value = 'opacity-75 cursor-not-allowed'
          }
        } else {
          classes.value = 'opacity-75 cursor-not-allowed'
        }
        return `bg-blue-600 ${classes.value}`
      }
    )
    const resetForm = useResetForm()
    const sendForm = () => {
      if ((_.size(errors.value) === 0 && (email.value || phone.value) && reason.value)) {
        if (reason.value === 4 && otherReason.value) {
          axiosApi.post('/unsubscribe-form', new UnsubscribeFormDto(data as UnsubscribeForm))
            .then(() => {
              submitted.value = true
            })
          resetForm()
        } else if (reason.value !== 4 && !otherReason.value) {
          segmentTracking('Clicked On Submit On Page')
          axiosApi.post('/unsubscribe-form', new UnsubscribeFormDto(data as UnsubscribeForm))
            .then(() => {
              submitted.value = true
            })
          resetForm()
        }
      }
    }

    return {
      errors,
      email,
      phone,
      reason,
      otherReason,
      clientId,
      submitted,
      data,
      checkFields,
      sendForm
    }
  }
})
