import { CCPAForm } from '@/models/interfaces/CCPAForm'

export default class CCPAFormDto {
  public readonly consumer: boolean
  public readonly phone: string
  public readonly reason: number
  public readonly firstName: string
  public readonly lastName: string
  public readonly zipcode: string
  public readonly email: string
  public readonly clientId: string
  public readonly campaignRequestId: string

  constructor (data: CCPAForm) {
    this.consumer = data.consumer ? Boolean(data.consumer) : false
    this.phone = data.phone ? String(data.phone) : '0'
    this.reason = data.reason ? Number(data.reason) : 0
    this.firstName = data.firstName ? String(data.firstName) : ''
    this.lastName = data.lastName ? String(data.lastName) : ''
    this.zipcode = data.zipcode ? String(data.zipcode) : ''
    this.email = data.email ? String(data.email) : ''
    this.clientId = data.clientId ? String(data.clientId) : ''
    this.campaignRequestId = data.campaignRequestId ? String(data.campaignRequestId) : ''
  }
}
