
import { computed, defineComponent, reactive, ref } from 'vue'
import { useMeta } from 'vue-meta'
import { axiosApi } from '@/models/http/axiosApi'
import { useField, useForm, useResetForm } from 'vee-validate'
import * as yup from 'yup'
import _ from 'lodash'
import { useCookie } from 'vue-cookie-next'
import ContactFormDto from '@/models/dto/ContactFormDto'
import { ContactForm, Validation } from '@/models/interfaces/ContactForm'
import { segmentTracking } from '@/helpers/clickEventTracking'

export default defineComponent({
  name: 'Contact',
  setup () {
    useMeta({
      title: 'Contact'
    })
    const schema = yup.object().shape({
      fullName: yup.string()
        .label('Full Name')
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .max(255)
        .min(2),
      email: yup.string().email()
        .when('email', {
          is: (value: string) => value?.length,
          then: yup.string().required()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
        }),
      phone: yup.string()
        .when('phone', {
          is: (value: string) => value?.length,
          then: yup.string()
            .notRequired()
            .typeError('Must be only digits')
            .matches(/^[0-9]{10}$/, 'Must be a valid phone number')
        }),
      subject: yup.string()
        .required()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .nullable(true)
        .min(2)
        .max(255),
      message: yup.string()
        .label('Message')
        .required()
        .nullable(true)
        .min(2)
        .max(2000)
    }, [['email', 'email'], ['phone', 'phone']])
    const { errors } = useForm<Validation>({
      validationSchema: schema
    })
    const { value: fullName } = useField('fullName')
    const { value: email } = useField('email')
    const { value: phone } = useField('phone')
    const { value: subject } = useField('subject')
    const { value: message } = useField('message')
    const { getCookie } = useCookie()
    const clientCookie = getCookie('client_id') ?? ''
    const { value: clientId } = ref(clientCookie)
    const campaignRequestId = getCookie('campaign_request_id') ?? ''
    const submitted = ref(false as boolean)
    const data = reactive({
      fullName,
      email,
      phone,
      subject,
      message,
      clientId,
      campaignRequestId
    })

    const checkFields = computed<string>(
      () => {
        const classes = ref('')
        classes.value = _.size(errors.value) === 0 && fullName.value && (email.value || phone.value) && subject.value && message.value
          ? 'cursor-pointer'
          : 'opacity-75 cursor-not-allowed'

        return `bg-blue-600 ${classes.value}`
      }
    )
    const resetForm = useResetForm()
    const sendForm = () => {
      if (_.size(errors.value) === 0 && fullName.value && (email.value || phone.value) && subject.value && message.value) {
        segmentTracking('Clicked On Submit On Page')
        axiosApi.post('/contact-form', new ContactFormDto(data as ContactForm))
          .then(() => {
            submitted.value = true
          })
        resetForm()
      }
    }

    return {
      errors,
      fullName,
      email,
      phone,
      subject,
      message,
      clientId,
      submitted,
      data,
      checkFields,
      sendForm
    }
  }
})
